import React from "react";
import { ActivityIndicator, LayoutChangeEvent, SafeAreaView, StyleSheet } from "react-native";
import { View, Text } from "../components/Themed";
import { Button } from "react-native-elements";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { kcColor } from "../constants/Colors";
import { ArrayExtensions, kcSetState, kcSetUnmount } from "../kcExternal";
import { PanGestureHandler, PinchGestureHandler, TapGestureHandler, GestureEvent, GestureEventPayload, PanGestureHandlerEventPayload, State, PinchGestureHandlerEventPayload } from "react-native-gesture-handler";
import YInfo, { YUnit } from "../kcTECP/YInfo";
import XInfo, { eXUnitDisplay } from "../kcTECP/XInfo";
import kcDrawHelper, { BasicTimeModel, Rectangle } from "../kcTECP/kcDrawHelper";
import { kcTECPCollection, TECPSettingEditHelper } from "../kcTECP";
import { delOnTECPChanged, LinePath, PathPoint, TopInfo } from "../kcTECP/kcTECPCollection";
import kcData from "../kcData";
import kcAnimated, { kcAnimatedDecay, kcAnimatedOneTime } from "../kcExternal/kcAnimated";
import { CompositeNavigationProp, RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { BottomTabParamList, TabTECPParamList } from "../types";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { HistoryDataType } from "../kcTransfer/InternalDefine";
import KC_HTypeModal, { cHType, FindcHType } from "../components/kcHTypeModal";
import KC_TECPScreenTopInfo, { TopItemClickType } from "../components/KC_TECPScreenTopInfo";
import { KLineDispalyType } from "../kcTECP/TECPSetting/TECPSettingDefine";
import { IsBottomTabHieghtToLow, ToLowCallback } from "../navigation/BottomTabNavigator";
import { FinTechCloudPaths, FinTechCloudValues, GetImage_Cloud_watch, Image_Cloud_Base } from "../kcTECP/TECPData/FinTechCloudData";
import { GetText, IsLocale } from "../Locales";
import { Device } from "../utils/kcGlobalSize";
import { kcGraphics, kcGraphicsHelper, TextStyle } from "../utils/kcGraphics";
import { View as DefaultView } from "react-native";

enum DeputyTECP {
   Vol = 0,
   KD = 1,
   RSI = 2,
   MACD = 3,
   MACDFT = 4,
}
enum SubTECP {
   None = 0,
   MA = 1,
   BBand = 2,
   FinTechCloud = 3,
}
const DeputyTECPArray = [DeputyTECP.MACDFT, DeputyTECP.KD, DeputyTECP.RSI];
const SubTECPArray = [SubTECP.None, SubTECP.FinTechCloud, SubTECP.MA];
// const DeputyTECPArray = Object.keys(DeputyTECP)
//   .filter((value) => isNaN(Number(value)) === false)
//   .map((value) => Number(value));
// const SubTECPArray = Object.keys(SubTECP)
//   .filter((value) => isNaN(Number(value)) === false)
//   .map((value) => Number(value));

type IState = {
   nFixedRedraw: number; // 笨方式 用來強制繪圖
   bIsScreenFocus: boolean;
   loading: boolean;
};

type IProp = {
   route: RouteProp<TabTECPParamList, "TabTECPScreen">;
   navigation: CompositeNavigationProp<StackNavigationProp<TabTECPParamList, "TabTECPScreen">, BottomTabNavigationProp<BottomTabParamList, "TabTECP">>;
};

export default class MoveDemo extends React.Component<IProp, IState> {
   state: IState = {
      loading: false,
      nFixedRedraw: 0, // 笨方式 用來強制繪圖
      bIsScreenFocus: true,
   };

   nFixedRedraw: number = 0;
   m_StockCode: string = "";
   m_HType: HistoryDataType = HistoryDataType.Minute;
   m_HBase: number = 1;
   m_cHType: cHType = new cHType();

   m_TECPS: any = (<></>);
   m_SubTECP: SubTECP = SubTECPArray[1]; // 主圖指標
   m_DeputyTECP: DeputyTECP = DeputyTECPArray[0]; // 副圖指標

   m_XInfo: XInfo = new XInfo();
   m_YInfo: YInfo = new YInfo();

   doubleTapRef: any = undefined;
   KC_HTypeModal;
   KC_TECPScreenTopInfo: any = undefined;
   m_AnimatedDecay_Pan: kcAnimatedDecay = new kcAnimatedDecay();
   m_AnimatedDecay_Pinch: kcAnimatedDecay = new kcAnimatedDecay();
   m_AnimatedDecay_Wheel: kcAnimatedOneTime = new kcAnimatedOneTime();
   m_Animated_PanLimit: kcAnimated = new kcAnimated();
   private AnimatedPanLimit_0_Value = 0;
   private AnimatedPanLimit_1_Value = 0;

   m_TECPData: kcTECPCollection;
   m_Init = false;
   m_Height: number = 0;
   m_Width: number = 0;

   m_BeginDataPos_Pan?: number = undefined;
   m_pDown_Pan?: { X: number; Y: number } = undefined;

   m_BeginDataDis_Pinch?: number = undefined;
   m_pDown_Pinch?: { X: number; Y: number } = undefined;

   m_bCheckLine: boolean = false;
   m_BeginCheckLinePos?: { X: number; Y: number } = undefined;
   m_pCheckLinePos?: { X: number; Y: number } = undefined;

   kcGraphics?: kcGraphics = undefined;

   constructor(props: any) {
      super(props);

      this.KC_HTypeModal = React.createRef<KC_HTypeModal>();
      this.doubleTapRef = React.createRef();
      this.m_TECPData = new kcTECPCollection(undefined, this.OnTECPCallback);
      this.m_Animated_PanLimit = new kcAnimated(0, 300);
      TECPSettingEditHelper.BeginEdit(this.m_TECPData);

      this.m_TECPData.FinTechCloud.OnHintCallback((_State) => {
         this.FixedRedrawUI();
      });
   }
   componentDidMount() {
      window.onwheel = this.OnWindowsMouseWheel;

      ToLowCallback((_ToLow) => {
         this.ChangeHeaderVisable(!_ToLow);
      });

      this.m_AnimatedDecay_Pan.SetCallback(this.OnAnimatedPanValueChange);
      this.m_AnimatedDecay_Pinch.SetCallback(this.OnAnimatedPinchValueChange);
      this.m_AnimatedDecay_Wheel.SetCallback(this.OnAnimatedWheelValueChange);
      this.m_Animated_PanLimit.SetCallback(this.OnAnimatedPanLimitValueChange);

      this.props.navigation.addListener("focus", this.OnFocus);
      this.props.navigation.addListener("blur", this.OnBlur);

      this.props.navigation.setOptions({
         headerLeft: this.Render_HeaderLeft.bind(this, this.m_cHType),
         headerRight: this.Render_HeaderRight,
      });

      this.ChangeHeaderVisable(!IsBottomTabHieghtToLow());

      TECPSettingEditHelper.SettingBuffer?.GetSettingAsync(false)
         .then(() => {})
         .catch(() => {})
         .finally(() => {
            this.m_cHType = FindcHType(this.m_TECPData.Setting.Global.DataType, this.m_TECPData.Setting.Global.DataMultiple);

            this.props.navigation.setOptions({
               headerLeft: this.Render_HeaderLeft.bind(this, this.m_cHType),
            });
         });
   }

   componentWillUnmount() {
      this.props.navigation.removeListener("focus", this.OnFocus);
      this.props.navigation.removeListener("blur", this.OnBlur);
      kcSetUnmount(this, true);
   }

   private FixedRedrawUI = () => {
      // this.m_TECPS = this.Render_TECPs();

      this.nFixedRedraw = this.nFixedRedraw + 1;
      kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
   };

   private ChangeFocusTECP = (_StockCode: string, _cHType: cHType) => {
      if (this.m_StockCode == _StockCode && this.m_cHType == _cHType) return;

      kcSetState(this, { loading: true });

      this.m_StockCode = _StockCode;
      this.m_cHType = _cHType;
      let TitleBind = this.Render_HeaderTitle.bind(this, _StockCode);
      let LeftBind = this.Render_HeaderLeft.bind(this, _cHType);
      this.props.navigation.setOptions({
         headerTitle: TitleBind,
         headerLeft: LeftBind,
      });

      this.StopAllAnimation();
      this.m_TECPData.ChangeTECP({
         StockCode: _StockCode,
         HType: _cHType.HType,
         HBase: _cHType.HBase,
         Recover: false,
      });

      // if (Unit) this.OnUnitDataChanged(Unit);
   };

   private GetAccount_async = async () => {
      let mdAccount = kcData.AccountLDF();
      if (!mdAccount) {
         setTimeout(this.GetAccount_async, 100);
         return;
      }

      this.FixedRedrawUI();
   };

   private StopAllAnimation = () => {
      this.m_AnimatedDecay_Pan.Stop();
      this.m_AnimatedDecay_Pinch.Stop();
      this.m_AnimatedDecay_Wheel.Stop();
      this.m_Animated_PanLimit.Stop();
   };

   private CheckingLineChange = (x?: number, y?: number) => {
      if (!x) x = (this.m_Width * 2) / 3;
      if (!y) y = (this.m_Height * 1) / 3;
      this.m_bCheckLine = !this.m_bCheckLine;
      this.m_pCheckLinePos = {
         X: x,
         Y: y,
      };
      this.FixedRedrawUI();
   };

   private ChangeSubTECP() {
      let nNextIdx = SubTECPArray.indexOf(this.m_SubTECP) + 1;
      if (nNextIdx >= SubTECPArray.length) nNextIdx = 0;

      this.m_SubTECP = SubTECPArray[nNextIdx];
      this.FixedRedrawUI();
   }
   private ChangeDeputyTECP() {
      let nNextIdx = DeputyTECPArray.indexOf(this.m_DeputyTECP) + 1;
      if (nNextIdx >= DeputyTECPArray.length) nNextIdx = 0;

      this.m_DeputyTECP = DeputyTECPArray[nNextIdx];
      this.FixedRedrawUI();
   }
   private ChangeHeaderVisable = (_Visable: boolean) => {
      this.props.navigation.setOptions({
         header: _Visable ? undefined : () => <></>,
      });
      //ChangeBottomTabVisable(_Visable);
      // this.props.navigation.dangerouslyGetParent()?.setOptions({
      //   tabBarVisible: _Visable,
      // });
   };

   /*---------------------------------------------------------------------------- */
   private OnFocus = () => {
      kcSetState(this, { bIsScreenFocus: true });

      let StockCode = "AAPL";
      if (this.props.route.params) StockCode = this.props.route.params.StockCode;
      this.ChangeFocusTECP(StockCode, this.m_cHType);
      this.GetAccount_async();
      //this.FixedRedrawUI();
   };
   private OnBlur = () => {
      kcSetState(this, { bIsScreenFocus: false });
      this.KC_HTypeModal.current?.Close();
   };

   private OnTECPCallback: delOnTECPChanged = (_TECP, _ChangedType) => {
      if (_ChangedType == "Init") {
         this.m_XInfo.ChangeTotalDataNum(_TECP.mlOHLC.length);
         this.m_XInfo.ChangeDisplayIndex(_TECP.mlOHLC.length - 1);
         kcSetState(this, { loading: false });
      } else if (_ChangedType == "Update") {
         this.m_XInfo.ChangeTotalDataNum(_TECP.mlOHLC.length);
         kcSetState(this, { loading: false });
      } else if (_ChangedType == "Clear") {
         kcSetState(this, { loading: true });
      }

      this.FixedRedrawUI();
   };

   /*---------------------------------------------------------------------------- */
   // Wheel
   private OnWindowsMouseWheel = (e: WheelEvent) => {
      if (!this.state.bIsScreenFocus) return;

      this.StopAllAnimation();
      this.m_pDown_Pinch = { X: e.clientX, Y: e.clientY };
      let EndDataDis = e.deltaY < 0 ? this.m_XInfo.DataDis * XInfo.DefaultZoomRate : this.m_XInfo.DataDis / XInfo.DefaultZoomRate;

      this.m_AnimatedDecay_Wheel.Start(this.m_XInfo.DataDis, EndDataDis, 780, 0.987);
   };

   private OnAnimatedWheelValueChange = (value: number, _bEnd: boolean) => {
      if (!this.m_pDown_Pinch) return;
      this.m_XInfo.Zoom_DataDis(value, this.m_pDown_Pinch.X);
      this.FixedRedrawUI();
   };

   /*---------------------------------------------------------------------------- */
   // Tap
   private OnTapHandlerStateChange = (event: any) => {
      let e = event.nativeEvent;
      this.StopAllAnimation();
      if (e.state == State.END) {
         if (this.m_bCheckLine) {
            this.m_pCheckLinePos = {
               X: e.x,
               Y: e.y,
            };
            this.FixedRedrawUI();
         } else {
            let nIdx = this.m_YInfo.GetFocusYUnitIndex(e.y);
            if (nIdx == 0) this.ChangeSubTECP();
            if (nIdx == 1) this.ChangeDeputyTECP();
         }
      }
   };
   private OnDoubleTapHandlerStateChange = (event: any) => {
      let e = event.nativeEvent;
      if (e.state != State.END) return;
      this.StopAllAnimation();
      this.CheckingLineChange(e.x, e.y);
   };

   /*---------------------------------------------------------------------------- */
   // Pan
   private OnPanGestureEvent = (
      event: GestureEvent<GestureEventPayload & PanGestureHandlerEventPayload> // 事實上裡面還有 target PointInside 可以使用
   ) => {
      let e = event.nativeEvent;
      if (e.state == State.BEGAN || e.state == State.END) {
         return;
      }

      // 影響到Zoom 關掉
      if (e.numberOfPointers == 2) return;

      if (!this.m_pDown_Pan || this.m_BeginDataPos_Pan === undefined) {
         this.m_pDown_Pan = { X: e.x, Y: e.y };
         this.m_BeginDataPos_Pan = this.m_XInfo.ScreenDataPos;
         this.m_BeginCheckLinePos = this.m_pCheckLinePos;
      }

      if (!this.m_bCheckLine) {
         this.m_AnimatedDecay_Pan.UpateAutoVelocity(e.x);
         let XDiff = this.m_pDown_Pan.X - e.x;
         let AnimatedValue = this.m_BeginDataPos_Pan + XDiff;
         this.m_XInfo.ChangeDisplayPos(AnimatedValue);
      } else {
         if (!this.m_BeginCheckLinePos) return;
         let CheckX = this.m_BeginCheckLinePos.X + e.translationX;
         let CheckY = this.m_BeginCheckLinePos.Y + e.translationY;
         if (CheckX < 2) CheckX = 2;
         if (CheckX > this.m_Width) CheckX = this.m_Width;

         if (CheckY < 0) CheckY = 0;
         if (CheckY > this.m_Height) CheckY = this.m_Height;

         this.m_pCheckLinePos = { X: CheckX, Y: CheckY };
      }
      this.FixedRedrawUI();
   };
   private OnPanGestureStateChange = (event: any) => {
      let e = event.nativeEvent;
      if (e.numberOfPointers == 2) {
         if (e.state == State.BEGAN) {
            //this.StopAllAnimation();
         }
         return;
      }

      if (e.state == State.BEGAN) {
         //this.StopAllAnimation();
      }
      if (e.state == State.END) {
         let XInfoScreenDataPos = this.m_XInfo.ScreenDataPos;
         this.m_AnimatedDecay_Pan.Start_AutoVelocity(0, XInfoScreenDataPos, 0.998, true);
         this.m_BeginDataPos_Pan = undefined;
      }
   };
   private OnAnimatedPanValueChange = (BaseValue: number, value: number, _bEnd: boolean) => {
      this.m_XInfo.ChangeDisplayPos(value);
      if (this.m_XInfo.ScreenEndDataPos == this.m_XInfo.m_fTotalDataWidth || this.m_XInfo.ScreenDataPos == 0) {
         _bEnd = true;
      }

      if (_bEnd) {
         this.StopAllAnimation();
         this.CheckRunNeedPanLimit();
      }
      this.FixedRedrawUI();
   };
   /*---------------------------------------------------------------------------- */
   // Pan Limit
   private CheckRunNeedPanLimit() {
      let bOverRight = this.m_XInfo.IsOverRightBuffer();
      let bOverLeft = this.m_XInfo.IsOverLeftBuffer();

      if (bOverRight) {
         this.AnimatedPanLimit_0_Value = this.m_XInfo.ScreenDataPos;
         this.AnimatedPanLimit_1_Value = this.m_XInfo.m_fTotalDataWidth - this.m_XInfo.TECPWidth - XInfo.OverLimitBuffer;
         this.m_Animated_PanLimit.RunUp();
      } else if (bOverLeft) {
         this.AnimatedPanLimit_0_Value = this.m_XInfo.ScreenDataPos;
         this.AnimatedPanLimit_1_Value = XInfo.OverLimitBuffer;
         this.m_Animated_PanLimit.RunUp();
      }
   }
   private OnAnimatedPanLimitValueChange = (value: any) => {
      let Pos = kcAnimated.TranferValue(value, this.AnimatedPanLimit_0_Value, this.AnimatedPanLimit_1_Value);
      this.m_XInfo.ChangeDisplayPos(Pos);
      this.FixedRedrawUI();
   };

   /*---------------------------------------------------------------------------- */
   // Pinch
   private OnPinchGestureEvent = (event: GestureEvent<PinchGestureHandlerEventPayload>) => {
      let e = event.nativeEvent;
      if (e.state == State.BEGAN || e.state == State.END) {
         return;
      }

      if (!this.m_BeginDataDis_Pinch || !this.m_pDown_Pinch) {
         this.m_BeginDataDis_Pinch = this.m_XInfo.DataDis;
         this.m_pDown_Pinch = { X: e.focalX, Y: e.focalY };
      }

      let NewDataDis = this.m_BeginDataDis_Pinch * e.scale;
      this.m_XInfo.Zoom_DataDis(NewDataDis, this.m_pDown_Pinch.X);
      this.m_AnimatedDecay_Pinch.UpateAutoVelocity(NewDataDis);
      this.FixedRedrawUI();
   };
   private OnPinchGestureStateChange = (event: any) => {
      let e = event.nativeEvent;

      if (e.state == State.BEGAN) {
         //this.StopAllAnimation();
         // IosWeb會從PanGesture的2指進去
      }
      if (e.state == State.END) {
         let XInfoDataDis = this.m_XInfo.DataDis;
         this.m_AnimatedDecay_Pinch.Start_AutoVelocity(0, XInfoDataDis, 0.995);
         this.m_BeginDataDis_Pinch = undefined;
      }
   };
   private OnAnimatedPinchValueChange = (BaseValue: number, value: number) => {
      if (!this.m_pDown_Pinch) return;
      this.m_XInfo.Zoom_DataDis(value, this.m_pDown_Pinch.X);
      this.FixedRedrawUI();
   };

   /*---------------------------------------------------------------------------- */
   // Left Right
   private OnBtnChangeHTypeClick = () => {
      this.KC_HTypeModal.current?.Show();
   };
   private OnModalSelectHType = (_cHype: cHType) => {
      this.ChangeFocusTECP(this.m_StockCode, _cHype);
   };
   private OnBtnGoSelectCommodity = () => {
      let RootNavigation = this.props.navigation;
      RootNavigation.push("TabTECPScreen_Select");
   };

   /*---------------------------------------------------------------------------- */
   private onLayout = (e: LayoutChangeEvent) => {
      let nWidth = e.nativeEvent.layout.width;
      let nHeight = e.nativeEvent.layout.height;

      if (nWidth === 0 || nHeight === 0) return; // 頁面切走的時候會是0, state.bIsScreenFocus可能來不及防

      if (!this.m_Init) {
         this.m_Init = true;
         this.m_XInfo.Init(100, this.m_TECPData.Setting.Global.XDataDis, e.nativeEvent.layout.width);
         if (this.m_TECPData && this.m_TECPData.mlOHLC.length != 0) {
            this.m_XInfo.ChangeTotalDataNum(this.m_TECPData.mlOHLC.length);
            this.m_XInfo.ChangeDisplayIndex(this.m_TECPData.mlOHLC.length - 1);
         }
         this.m_YInfo.Init(e.nativeEvent.layout.height, this.m_TECPData.Setting.Global.ShowDeputyTECP);
      }

      this.m_Width = e.nativeEvent.layout.width;
      this.m_Height = e.nativeEvent.layout.height;
      this.m_XInfo.ChangePictureWidth(this.m_Width);
      this.m_YInfo.ChangeHeight(this.m_Height, this.m_TECPData.Setting.Global.ShowDeputyTECP);

      // 延遲100ms重畫, 減少切子頁面回來時高度會閃的問題
      if (!this.state.bIsScreenFocus) return;

      setTimeout(() => {
         this.FixedRedrawUI();
      }, 100);
   };

   private OnTopInfoItmeClick = (_ClickItem: TopItemClickType, _Param0?: any) => {
      switch (_ClickItem) {
         case "Checking":
            this.CheckingLineChange();
            break;
         case "Setting":
            TECPSettingEditHelper.BeginEdit(this.m_TECPData);
            this.props.navigation.push("TabTECPScreen_Setting");
            break;
         case "cHType":
            this.OnBtnChangeHTypeClick();
            break;
         case "SelectCommodity":
            this.OnBtnGoSelectCommodity();
            break;
      }
   };

   private Render_HeaderLeft = (_Param: cHType) => {
      return <Button iconContainerStyle={{ alignSelf: "center" }} buttonStyle={styles.button} titleStyle={{ fontSize: 18 }} title={_Param.sz} onPress={this.OnBtnChangeHTypeClick} />;
   };

   private Render_HeaderRight = () => {
      return (
         <Button
            // style={{ width: 48, height: 48 }}
            style={{ backgroundColor: "#00000000" }}
            buttonStyle={{ backgroundColor: "#00000000" }}
            onPress={this.OnBtnGoSelectCommodity}
            titleStyle={{ fontSize: 16 }}
            // title={"其他"}
            // icon={<Ionicons name="ios-chevron-forward" size={20} color="#ffffff" />}
            icon={<MaterialCommunityIcons name="text-search" size={28} color={"#ffffff"} />}
            // iconPosition="right"
            //iconRight={true}
            iconContainerStyle={{ alignSelf: "center" }}
         />
      );
   };

   private Render_HeaderTitle = (_Param: string) => {
      let szTitleText = _Param;
      let mdCommodity = kcData.GetCommodity(_Param);
      if (mdCommodity) szTitleText = mdCommodity.LocaleStockName();

      return (
         <View
            style={{
               backgroundColor: "#00000000",
               //flex: 1,
               width: 300,
               flexDirection: "column",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Text
               style={{
                  backgroundColor: "#00000000",
                  color: kcColor("Title"),
                  fontSize: 18,
               }}
               selectable={false}
            >
               {szTitleText}
            </Text>
         </View>
      );
   };

   private RedrawTECP = () => {
      if (!this.kcGraphics) return;
      if (!this.m_TECPData || !this.m_TECPData.mlOHLC || !this.m_TECPData.mlOHLC.length) return;

      let TimeLines = kcDrawHelper.GetBasicInfo_Time(this.m_TECPData.mlOHLC, this.m_XInfo, this.m_TECPData.HistoryParams);
      let DailyLines = kcDrawHelper.GetDailyLines(this.m_TECPData.mlOHLC, this.m_XInfo, this.m_TECPData.HistoryParams, this.m_TECPData.Commodity);

      this.kcGraphics.clearRect(0, 0, this.m_Width * Device.PixelRatio, this.m_Height * Device.PixelRatio);

      let bShowDeputyTECP = this.m_TECPData.Setting.Global.ShowDeputyTECP;
      this.Redraw_KLine(TimeLines, DailyLines);

      if (bShowDeputyTECP) {
         if (this.m_DeputyTECP === DeputyTECP.Vol) this.Redraw_Vol(TimeLines, DailyLines);
         else if (this.m_DeputyTECP === DeputyTECP.KD) this.Redraw_KD(TimeLines, DailyLines);
         else if (this.m_DeputyTECP === DeputyTECP.RSI) this.Redraw_RSI(TimeLines, DailyLines);
         else if (this.m_DeputyTECP === DeputyTECP.MACD) this.Redraw_MACD(TimeLines, DailyLines);
         else if (this.m_DeputyTECP === DeputyTECP.MACDFT) this.Redraw_MACDFT(TimeLines, DailyLines);
      }

      this.Redraw_Time(TimeLines, DailyLines);
      this.Redraw_SpecHLine(TimeLines, DailyLines);
      this.Redraw_CheckLine();
   };

   private Redraw_KLine = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]): void => {
      if (!this.kcGraphics) return;

      let YUnit = this.m_YInfo.YUnits[0];
      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;
      let FloatNum = 2;

      if (this.m_TECPData?.kcUnit?.Commodity) {
         let Com = this.m_TECPData.kcUnit.Commodity;
         FloatNum = Com.FloatNum;
         YUnit.SetTickBase(Com.PriceScale[0].TickPrice);
      }

      let GSetting = this.m_TECPData.Setting.Global;
      let KLineSetting = this.m_TECPData.Setting.KLine;

      let Max: { Value: number; DataIdx: number; PosX: number } = {
         Value: Number.MIN_VALUE,
         DataIdx: -1,
         PosX: 0,
      };
      let Min: { Value: number; DataIdx: number; PosX: number } = {
         Value: Number.MAX_VALUE,
         DataIdx: -1,
         PosX: 0,
      };

      this.m_XInfo.lXUnit.map((md) => {
         if (md.eXUnitDisplay == eXUnitDisplay.Empty) return;
         let nDataIdx = md.nDataIndex;
         let Data = this.m_TECPData.mlOHLC[nDataIdx];
         if (!Data) return;
         if (Max.Value < Data.HighPrice) Max = { Value: Data.HighPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
         if (Min.Value > Data.LowPrice) Min = { Value: Data.LowPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
      });

      if (Min.DataIdx === -1 && Max.DataIdx === -1 && this.m_XInfo.lXUnit.length > 0) {
         let md = this.m_XInfo.lXUnit[0];
         let nDataIdx = md.nDataIndex;
         if (nDataIdx > -1 && nDataIdx < this.m_TECPData.mlOHLC.length) {
            let Data = this.m_TECPData.mlOHLC[nDataIdx];
            Max = { Value: Data.HighPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
            Min = { Value: Data.LowPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
         }
      }

      let KLineMax = { ...Max };
      let KLineMin = { ...Min };
      if (this.m_XInfo.lXUnit.length > 0) YUnit.ChangeYValue(Min.Value, Max.Value);

      let aMaPaths: (LinePath | undefined)[] | undefined = undefined;
      // Ma疊圖
      if (this.m_SubTECP === SubTECP.MA) {
         let SubDatas = [this.m_TECPData.KLine_MA0, this.m_TECPData.KLine_MA1, this.m_TECPData.KLine_MA2, this.m_TECPData.KLine_MA3];
         let SubValues = SubDatas.map((_Data) => _Data.GetValues(this.m_XInfo.lXUnit));
         SubValues.forEach((_SubValue, idx) => {
            if (!_SubValue) return;
            if (!SubDatas[idx].Setting.UpdateMaxMinLimit) return;
            if (_SubValue.Max !== undefined) Max.Value = Math.max(Max.Value, _SubValue.Max);
            if (_SubValue.Min !== undefined) Min.Value = Math.min(Min.Value, _SubValue.Min);
         });

         if (this.m_XInfo.lXUnit.length > 0) YUnit.ChangeYValue(Min.Value, Max.Value);

         aMaPaths = SubValues.map((_SubValue, idx) => {
            return SubDatas[idx].GetLinePoints(_SubValue, YUnit);
         });
      }

      // // BBand疊圖
      // if (this.m_SubTECP === SubTECP.BBand) {
      //   let SubData = this.m_TECPData.BBand;
      //   let SubValue = SubData.GetValues(this.m_XInfo.lXUnit);
      //   if (SubValue) {
      //     if (SubValue.Max !== undefined)
      //       Max.Value = Math.max(Max.Value, SubValue.Max);
      //     if (SubValue.Min !== undefined)
      //       Min.Value = Math.min(Min.Value, SubValue.Min);
      //     YUnit.ChangeYValue(Min.Value, Max.Value);

      //     let Path = SubData.GetSVGPath(SubValue, YUnit);
      //     if (Path) SubPaths.push(...Path);
      //   }
      // }

      // 觔斗雲疊圖
      let mdFinTechCloudValue: FinTechCloudValues | undefined = undefined;
      let mdFinTechCloudPath: FinTechCloudPaths | undefined = undefined;
      if (this.m_SubTECP === SubTECP.FinTechCloud) {
         let SubData = this.m_TECPData.FinTechCloud;
         let SubValue = SubData.GetValues(this.m_XInfo.lXUnit);
         if (SubValue) {
            if (SubValue.Max !== undefined) Max.Value = Math.max(Max.Value, SubValue.Max);
            if (SubValue.Min !== undefined) Min.Value = Math.min(Min.Value, SubValue.Min);
            YUnit.ChangeYValue(Min.Value, Max.Value);
         }

         mdFinTechCloudValue = SubValue;
         mdFinTechCloudPath = SubData.GetPath(SubValue, YUnit);
      }

      let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
      let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
      // let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      let TopInfos = [this.m_TECPData.TopInfo_KLine(false, false, false)];
      if (this.m_SubTECP === SubTECP.MA) TopInfos.push(this.m_TECPData.TopInfo_MA());
      if (this.m_SubTECP === SubTECP.BBand) TopInfos.push(this.m_TECPData.TopInfo_BBand());
      if (this.m_SubTECP === SubTECP.FinTechCloud) TopInfos.push(this.m_TECPData.TopInfo_FinTechCloud());

      /* --------------------------------------------------------- */
      // BackGround
      var g_BG = this.kcGraphics;
      this.DrawBackground_Default(g_BG, YUnit, _TimeLines, _DailyLines);

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;

      let Clip: Rectangle = {
         X: XStartCoord,
         Y: YUnit.nEffectStartY,
         Width: XEndCoord - XStartCoord,
         Height: YUnit.nEffectHigh,
      };
      let { RestoreClip } = kcGraphicsHelper.SetClip(g_TECP, Clip); // Clip

      // 觔斗雲 底圖
      if (mdFinTechCloudPath) this.Draw_FinTechCloud(g_TECP, YUnit, mdFinTechCloudPath, true, Clip);

      // KLine
      if (KLineSetting.DispalyType === KLineDispalyType.NormalKLine) this.Redraw_KLine_NormalKLine(g_TECP, YUnit);
      else if (KLineSetting.DispalyType === KLineDispalyType.USKLine) this.Redraw_KLine_USKLine(g_TECP, YUnit);
      else if (KLineSetting.DispalyType === KLineDispalyType.CloseKLine) this.Redraw_KLine_CloseKLine(g_TECP, YUnit);

      this.Draw_KLine_MaxMin(YUnit, KLineMax, KLineMin, Clip);

      // MA 疊圖
      if (aMaPaths && aMaPaths.length > 0) {
         for (let mdMaPath of aMaPaths) {
            if (mdMaPath === undefined) continue;
            let fLineWidth = mdMaPath.PathWidth == 1 ? 1.5 : mdMaPath.PathWidth;
            kcGraphicsHelper.DrawLine(g_TECP, fLineWidth, mdMaPath.PathColor, mdMaPath.Points);
         }
      }

      // 觔斗雲 疊圖
      if (mdFinTechCloudPath) {
         this.Draw_FinTechCloud(g_TECP, YUnit, mdFinTechCloudPath, false, Clip);
         this.Draw_FinTechCloudHint(g_TECP, YUnit, Clip);
      }

      RestoreClip();

      this.Draw_TopInfo(g_BG, YUnit, GetText("TECP_KLine"), TopInfos);
   };
   private Redraw_KLine_NormalKLine = (_g: kcGraphics, _YUnit: YUnit) => {
      let KLineSetting = this.m_TECPData.Setting.KLine;

      let cVLineColor = KLineSetting.VerticalLineColor;
      let cUpColor = KLineSetting.UpKColor;
      let cDownColor = KLineSetting.DownKColor;
      let cEqualKColor = KLineSetting.EqualKColor;

      this.m_XInfo.lXUnit.forEach((md, idx) => {
         let Data = this.m_TECPData.mlOHLC[md.nDataIndex];
         if (!Data) return;
         if (md.fXRightPos < 0) return;
         let YOpenPrice = _YUnit.ValueToCoord(Data.OpenPrice);
         let YHighPrice = _YUnit.ValueToCoord(Data.HighPrice);
         let YLowPrice = _YUnit.ValueToCoord(Data.LowPrice);
         let YClosePrice = _YUnit.ValueToCoord(Data.ClosePrice);
         let HDiffOC = Math.abs(YOpenPrice - YClosePrice);
         let HDiffHL = Math.abs(YHighPrice - YLowPrice);

         kcGraphicsHelper.FillRect(_g, cVLineColor, Math.round(md.fXMidPos), YHighPrice, 1, HDiffHL); // -0.5 修正反鋸齒

         if (Data.OpenPrice < Data.ClosePrice) {
            kcGraphicsHelper.FillRect(_g, cUpColor, md.fXLeftPos, YClosePrice, md.fXWidth, HDiffOC);
         }

         if (Data.OpenPrice > Data.ClosePrice) {
            kcGraphicsHelper.FillRect(_g, cDownColor, md.fXLeftPos, YOpenPrice, md.fXWidth, HDiffOC);
         }

         if (Data.OpenPrice == Data.ClosePrice) {
            kcGraphicsHelper.FillRect(_g, cEqualKColor, md.fXLeftPos, Math.round(YOpenPrice), md.fXWidth, 1);
         }
      });
   };
   private Redraw_KLine_USKLine = (_g: kcGraphics, _YUnit: YUnit) => {
      let KLineSetting = this.m_TECPData.Setting.KLine;

      let Line_Up: PathPoint[] = [];
      let Line_Equal: PathPoint[] = [];
      let Line_Down: PathPoint[] = [];

      for (let md of this.m_XInfo.lXUnit) {
         let Data = this.m_TECPData.mlOHLC[md.nDataIndex];
         if (!Data) return;
         let YOpenPrice = _YUnit.ValueToCoord(Data.OpenPrice);
         let YHighPrice = _YUnit.ValueToCoord(Data.HighPrice);
         let YLowPrice = _YUnit.ValueToCoord(Data.LowPrice);
         let YClosePrice = _YUnit.ValueToCoord(Data.ClosePrice);

         let LinePath = Line_Equal;
         if (Data.OpenPrice < Data.ClosePrice) LinePath = Line_Up; // szColor = KLineSetting.UpKColor;
         else if (Data.OpenPrice > Data.ClosePrice) LinePath = Line_Down; // szColor = KLineSetting.DownKColor;

         let XLeftPos = Math.round(md.fXLeftPos);
         let fXMidPos = Math.round(md.fXMidPos);
         let fXRightPos = Math.round(md.fXRightPos);

         YOpenPrice = Math.round(YOpenPrice);
         YHighPrice = Math.round(YHighPrice);
         YLowPrice = Math.round(YLowPrice);
         YClosePrice = Math.round(YClosePrice);

         if (fXMidPos - XLeftPos < 1.5) XLeftPos = fXMidPos - 1.5;
         if (fXRightPos - fXMidPos < 1.5) fXRightPos = fXMidPos + 1.5;

         // 開盤橫線
         LinePath.push({ X: XLeftPos, Y: YOpenPrice, StartPoint: true });
         LinePath.push({ X: fXMidPos, Y: YOpenPrice });

         // 中垂線
         LinePath.push({ X: fXMidPos, Y: YHighPrice, StartPoint: true });
         LinePath.push({ X: fXMidPos, Y: YLowPrice });

         // 收盤橫線
         LinePath.push({ X: fXMidPos, Y: YClosePrice, StartPoint: true });
         LinePath.push({ X: fXRightPos, Y: YClosePrice });
      }

      let nLineWidth = 1.5;
      kcGraphicsHelper.DrawLine(_g, nLineWidth, KLineSetting.DownKColor, Line_Down);
      kcGraphicsHelper.DrawLine(_g, nLineWidth, KLineSetting.EqualKColor, Line_Equal);
      kcGraphicsHelper.DrawLine(_g, nLineWidth, KLineSetting.UpKColor, Line_Up);
   };
   private Redraw_KLine_CloseKLine = (_g: kcGraphics, _YUnit: YUnit) => {
      let KLineSetting = this.m_TECPData.Setting.KLine;
      let cColor = KLineSetting.EqualKColor;

      let Line: PathPoint[] = [];
      for (let i = 0; i < this.m_XInfo.lXUnit.length; i++) {
         let md = this.m_XInfo.lXUnit[i];
         let Data = this.m_TECPData.mlOHLC[md.nDataIndex];
         let dValue = Data.ClosePrice;
         let XCoord = md.fXMidPos;
         let YCoord = _YUnit.ValueToCoord(dValue);

         Line.push({ X: XCoord, Y: YCoord });
      }

      kcGraphicsHelper.DrawLine(_g, 1.5, cColor, Line);
   };
   private Draw_FinTechCloud = (_g: kcGraphics, _YUnit: YUnit, mdFinTechCloudPath: FinTechCloudPaths, _bBack: boolean, _Clip?: Rectangle) => {
      if (_bBack) {
         for (let _Line of mdFinTechCloudPath.BackLines) kcGraphicsHelper.DrawLine(_g, _Line.PathWidth, _Line.PathColor, _Line.Points);
      } else {
         // Line
         for (let _Line of mdFinTechCloudPath.ForeLines) kcGraphicsHelper.DrawLine(_g, _Line.PathWidth, _Line.PathColor, _Line.Points);

         // Rectangle
         for (let RectGroup of mdFinTechCloudPath.Rects) {
            let nColor = RectGroup.Color;
            for (let Rect of RectGroup.Paths) {
               kcGraphicsHelper.FillRect(_g, nColor, Rect.X, Rect.Y, Rect.Width, Rect.Height);
            }
         }

         // Image
         for (let _Image of mdFinTechCloudPath.Images) {
            for (let ImageItem of _Image.Paths) kcGraphicsHelper.DrawImage(_g, _Image.Image, ImageItem.X, ImageItem.Y, ImageItem.Width, ImageItem.Height);
         }

         // Text
         let FontSize = 12;
         mdFinTechCloudPath.Strings.forEach((_String) => {
            let HAlient: "left" | "center" | "right" = "left";
            let VAlient: "top" | "middle" | "bottom" = "bottom";

            if (_String.Alignment == "Center") HAlient = "center";
            else if (_String.Alignment == "Far") HAlient = "right";

            if (_String.LineAlignment == "Center") VAlient = "middle";
            else if (_String.LineAlignment == "Near") VAlient = "top";

            let FontStyle: TextStyle = { Size: FontSize, Fill: _String.Color, HAlient, VAlient };
            kcGraphicsHelper.DrawText(_g, _String.Value, _String.X, _String.Y, FontStyle);
         });
      }
   };
   private Draw_FinTechCloudHint = (_g: kcGraphics, _YUnit: YUnit, _Clip?: Rectangle) => {
      let Hint = this.m_TECPData.FinTechCloud.HintState;
      if (Hint && Hint.AnimatedValue > 0) {
         let nWidth = 100;
         let nHeight = 100;
         let Y = _YUnit.nEffectStartY + _YUnit.nEffectHigh / 2 - nHeight / 2;
         let LastDataIndex = this.m_TECPData.mlOHLC.length;
         let X = this.m_XInfo.DataIndexToDrawPos(LastDataIndex) + 20;

         // Image
         kcGraphicsHelper.DrawImage(_g, Hint.Image, X, Y, nWidth, nHeight, Hint.AnimatedValue);
      }
   };

   private Draw_KLine_MaxMin = (_YUnit: YUnit, _Max: { Value: number; DataIdx: number; PosX: number }, _Min: { Value: number; DataIdx: number; PosX: number }, _Clip?: Rectangle) => {
      if (!this.kcGraphics) return;

      const g = this.kcGraphics;
      let fontSize = 12;
      // let nStartY = _YUnit.StartY + 3;
      let nEndY = _YUnit.EndY - 3;
      let FloatNum = _YUnit.YTickBaseFloatNum;

      // Clip
      // let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊

      // Max
      let MaxValue = _Max.Value.toFixed(FloatNum);
      let style_Max: TextStyle = { Size: fontSize, Fill: kcColor("Up"), HAlient: "left", VAlient: "top" };
      let Max_Size = kcGraphicsHelper.MeasureText(g, MaxValue, style_Max);
      //let Max_PosY = nStartY + 2 * _YUnit.YLeftInfoHeight + _YUnit.YLeftInfoHeight;
      let Max_PosY = _YUnit.nEffectStartY;
      let Max_PosX = _Max.PosX - Max_Size.width / 2;
      Max_PosX = Math.max(0, Math.min(XEndCoord - Max_Size.width, Max_PosX));

      //Min
      let MinValue = _Min.Value.toFixed(FloatNum);
      let style_Min: TextStyle = { Size: fontSize, Fill: kcColor("Down"), HAlient: "left", VAlient: "bottom" };
      let Min_Size = kcGraphicsHelper.MeasureText(g, MinValue);
      let Min_PosY = nEndY;
      let Min_PosX = _Min.PosX - Min_Size.width / 2;
      Min_PosX = Math.max(0, Math.min(XEndCoord - Min_Size.width, Min_PosX));

      kcGraphicsHelper.DrawText(g, MaxValue, Max_PosX, Max_PosY, style_Max);
      kcGraphicsHelper.DrawText(g, MinValue, Min_PosX, Min_PosY + 3, style_Min);
   };

   private Draw_TopInfo = (_g: kcGraphics, _YUnit: YUnit, _Title: string, _aInfos: TopInfo[][]) => {
      let nStartY = _YUnit.StartY + 3;
      // let nEndY = _YUnit.nEffectStartY - 1;
      // let nHeight = _YUnit.YLeftInfoHeight * _aInfos.length;

      // let Clip: Rectangle = {
      //    X: 0,
      //    Y: nStartY,
      //    Width: this.m_Width,
      //    Height: nHeight + 1,
      // };
      let FontSize_Title = 14;
      let FontSize = 12;
      let szColor_Default = kcColor("SubTitle");

      let FontStyle: TextStyle = { Size: FontSize_Title, Fill: szColor_Default, HAlient: "left", VAlient: "bottom" };
      kcGraphicsHelper.DrawText(_g, _Title, 5, nStartY + _YUnit.YLeftInfoHeight, FontStyle);

      for (let Idx = 0; Idx < _aInfos.length; Idx++) {
         let Row = _aInfos[Idx];
         let YPos = nStartY + _YUnit.YLeftInfoHeight * (Idx + 1);
         let XPos = Idx === 0 ? (IsLocale("en") ? 55 : 45) : 0;

         for (let v of Row) {
            // Text
            let TextColor = v.TextColor !== undefined ? v.TextColor : szColor_Default;
            let FontStyle_Text: TextStyle = { Size: FontSize, Fill: TextColor, HAlient: "left", VAlient: "bottom" };
            let TextSize = kcGraphicsHelper.MeasureText(_g, v.Text);
            XPos += 6;

            kcGraphicsHelper.DrawText(_g, v.Text, XPos, YPos, FontStyle_Text);
            XPos += TextSize.width;

            // Value
            let ValueColor = v.Color !== undefined ? v.Color : szColor_Default;
            let FontStyle_Value: TextStyle = { Size: FontSize, Fill: ValueColor, HAlient: "left", VAlient: "bottom" };
            let ValueSize = kcGraphicsHelper.MeasureText(_g, v.Value);
            XPos += 3;

            kcGraphicsHelper.DrawText(_g, v.Value, XPos, YPos, FontStyle_Value);
            XPos += ValueSize.width;
         }
      }
   };

   private Redraw_Vol = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      if (!this.kcGraphics) return;
      let YUnit = this.m_YInfo.YUnits[1];
      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;
      YUnit.SetTickBase(0.01);
      YUnit.ChangeYValue(0, 100);

      let GSetting = this.m_TECPData.Setting.Global;
      let KDSetting = this.m_TECPData.Setting.KD;

      let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
      let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      let nlGridLines: { Value: number; LineColor: string; LineWidth: number }[] = [];
      if (KDSetting.BHL_0_Enable) nlGridLines.push({ Value: KDSetting.BHL_0_Value, LineColor: KDSetting.BHL_0_LineColor, LineWidth: KDSetting.BHL_0_LineWidth });
      if (KDSetting.BHL_1_Enable) nlGridLines.push({ Value: KDSetting.BHL_1_Value, LineColor: KDSetting.BHL_1_LineColor, LineWidth: KDSetting.BHL_1_LineWidth });
      if (KDSetting.BHL_2_Enable) nlGridLines.push({ Value: KDSetting.BHL_2_Value, LineColor: KDSetting.BHL_2_LineColor, LineWidth: KDSetting.BHL_2_LineWidth });

      let KDValues = this.m_TECPData.KD.GetValues(this.m_XInfo.lXUnit);
      let KDLinePath = this.m_TECPData.KD.GetPath(KDValues, YUnit);

      // TopInfo
      let TopInfoData = this.m_TECPData.KD.GetTopInfo();

      /* --------------------------------------------------------- */
      // BackGround
      var g_BG = this.kcGraphics;

      this.DrawBackground_Border(g_BG, YUnit);
      this.DrawBackground_HGrid(g_BG, YUnit, nlGridLines);
      this.DrawBackground_VGrid(g_BG, YUnit, _TimeLines, _DailyLines);

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;

      // Clip
      let { RestoreClip } = kcGraphicsHelper.SetClip(g_TECP, {
         X: XStartCoord,
         Y: YUnit.nEffectStartY,
         Width: Math.max(0, XEndCoord - XStartCoord),
         Height: YUnit.nEffectHigh - 1,
      });

      if (KDLinePath && KDLinePath.length > 0) {
         for (let md of KDLinePath) {
            let fLineWidth = md.PathWidth == 1 ? 1.5 : md.PathWidth;
            kcGraphicsHelper.DrawLine(g_TECP, fLineWidth, md.PathColor, md.Points);
         }
      }

      RestoreClip();

      // TopInfo
      if (TopInfoData) this.Draw_TopInfo(g_BG, YUnit, "VOL", [TopInfoData]);
   };
   private Redraw_KD = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      if (!this.kcGraphics) return;
      let YUnit = this.m_YInfo.YUnits[1];
      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;
      YUnit.SetTickBase(0.01);
      YUnit.ChangeYValue(0, 100);

      let GSetting = this.m_TECPData.Setting.Global;
      let KDSetting = this.m_TECPData.Setting.KD;

      let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
      let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      let nlGridLines: { Value: number; LineColor: string; LineWidth: number }[] = [];
      if (KDSetting.BHL_0_Enable) nlGridLines.push({ Value: KDSetting.BHL_0_Value, LineColor: KDSetting.BHL_0_LineColor, LineWidth: KDSetting.BHL_0_LineWidth });
      if (KDSetting.BHL_1_Enable) nlGridLines.push({ Value: KDSetting.BHL_1_Value, LineColor: KDSetting.BHL_1_LineColor, LineWidth: KDSetting.BHL_1_LineWidth });
      if (KDSetting.BHL_2_Enable) nlGridLines.push({ Value: KDSetting.BHL_2_Value, LineColor: KDSetting.BHL_2_LineColor, LineWidth: KDSetting.BHL_2_LineWidth });

      let KDValues = this.m_TECPData.KD.GetValues(this.m_XInfo.lXUnit);
      let KDLinePath = this.m_TECPData.KD.GetPath(KDValues, YUnit);

      // TopInfo
      let TopInfoData = this.m_TECPData.KD.GetTopInfo();

      /* --------------------------------------------------------- */
      // BackGround
      var g_BG = this.kcGraphics;

      this.DrawBackground_Border(g_BG, YUnit);
      this.DrawBackground_HGrid(g_BG, YUnit, nlGridLines);
      this.DrawBackground_VGrid(g_BG, YUnit, _TimeLines, _DailyLines);

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;

      // Clip
      let { RestoreClip } = kcGraphicsHelper.SetClip(g_TECP, {
         X: XStartCoord,
         Y: YUnit.nEffectStartY,
         Width: Math.max(0, XEndCoord - XStartCoord),
         Height: YUnit.nEffectHigh - 1,
      });

      if (KDLinePath && KDLinePath.length > 0) {
         for (let md of KDLinePath) {
            let fLineWidth = md.PathWidth == 1 ? 1.5 : md.PathWidth;
            kcGraphicsHelper.DrawLine(g_TECP, fLineWidth, md.PathColor, md.Points);
         }
      }

      RestoreClip();

      // TopInfo
      if (TopInfoData) this.Draw_TopInfo(g_BG, YUnit, "KD", [TopInfoData]);
   };
   private Redraw_RSI = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      if (!this.kcGraphics) return;
      let YUnit = this.m_YInfo.YUnits[1];
      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;
      YUnit.SetTickBase(0.01);
      YUnit.ChangeYValue(0, 100);

      let GSetting = this.m_TECPData.Setting.Global;
      let RSISetting = this.m_TECPData.Setting.RSI;

      // let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
      let nlGridLines = [25, 50, 75];
      let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
      let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      let RSIValues = this.m_TECPData.RSI.GetValues(this.m_XInfo.lXUnit);
      let RSILinePath = this.m_TECPData.RSI.GetPath(RSIValues, YUnit);

      // TopInfo
      let TopInfoData = this.m_TECPData.RSI.GetTopInfo();

      /* --------------------------------------------------------- */
      // BackGround
      var g_BG = this.kcGraphics;
      this.DrawBackground_Default(g_BG, YUnit, _TimeLines, _DailyLines);

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;

      // Clip
      let { RestoreClip } = kcGraphicsHelper.SetClip(g_TECP, {
         X: XStartCoord,
         Y: YUnit.nEffectStartY,
         Width: Math.max(0, XEndCoord - XStartCoord),
         Height: YUnit.nEffectHigh - 1,
      });

      if (RSILinePath && RSILinePath.length > 0) {
         for (let md of RSILinePath) {
            let fLineWidth = md.PathWidth == 1 ? 1.5 : md.PathWidth;
            kcGraphicsHelper.DrawLine(g_TECP, fLineWidth, md.PathColor, md.Points);
         }
      }

      RestoreClip();

      // TopInfo
      if (TopInfoData) this.Draw_TopInfo(g_BG, YUnit, "RSI", [TopInfoData]);
   };
   private Redraw_MACD = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      if (!this.kcGraphics) return;
      let YUnit = this.m_YInfo.YUnits[1];
      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;
      YUnit.SetTickBase(0.0001);

      let TECPData = this.m_TECPData.MACDFT;
      let MACDValues = TECPData.GetValues(this.m_XInfo.lXUnit);
      if (MACDValues && MACDValues.Min && MACDValues.Max) YUnit.ChangeYValue(MACDValues.Min, MACDValues.Max);

      let LinePaths = TECPData.GetPath(MACDValues, YUnit);

      let GSetting = this.m_TECPData.Setting.Global;
      let MACDSetting = this.m_TECPData.Setting.MACDFT;

      let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
      let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
      let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      // TopInfo
      let TopInfoData = TECPData.GetTopInfo();

      /* --------------------------------------------------------- */
      // BackGround
      var g_BG = this.kcGraphics;
      this.DrawBackground_Default(g_BG, YUnit, _TimeLines, _DailyLines);

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;
      let { RestoreClip } = kcGraphicsHelper.SetClip(g_TECP, {
         X: XStartCoord,
         Y: YUnit.nEffectStartY,
         Width: Math.max(0, XEndCoord - XStartCoord),
         Height: YUnit.nEffectHigh - 1,
      });

      // let Dif_MACD_UpColor = ToHex(MACDSetting.Dif_MACD_UpColor);
      // let Dif_MACD_DownColor = ToHex(MACDSetting.Dif_MACD_DownColor);
      let Dif_MACD_UpColor = this.m_TECPData.Setting.KLine.UpKColor; // 與KLine顏色同步
      let Dif_MACD_DownColor = this.m_TECPData.Setting.KLine.DownKColor; // 與KLine顏色同步

      if (MACDValues) {
         MACDValues.XUnitValues.forEach((md) => {
            let Data = md.Value;
            //let Dif_MACD = Data.DIF_MACD;
            let Dif_MACD = Data.L2;
            if (!Data || !Dif_MACD) return;
            let Y0 = YUnit.ValueToCoord(0);
            let YMACD = YUnit.ValueToCoord(Dif_MACD);
            let HDiffOC = Math.abs(YMACD - Y0);
            HDiffOC = Math.max(HDiffOC, 1);

            if (Dif_MACD >= 0) {
               kcGraphicsHelper.FillRect(g_TECP, Dif_MACD_UpColor, md.XUnit.fXLeftPos, YMACD, md.XUnit.fXWidth, HDiffOC);
            } else {
               kcGraphicsHelper.FillRect(g_TECP, Dif_MACD_DownColor, md.XUnit.fXLeftPos, Y0, md.XUnit.fXWidth, HDiffOC);
            }
         });

         if (LinePaths && LinePaths.length > 0) {
            for (let md of LinePaths) {
               let fLineWidth = md.PathWidth == 1 ? 1.5 : md.PathWidth;
               kcGraphicsHelper.DrawLine(g_TECP, fLineWidth, md.PathColor, md.Points);
            }
         }
      }

      RestoreClip();

      // TopInfo
      if (TopInfoData) this.Draw_TopInfo(g_BG, YUnit, "MACD", [TopInfoData]);
   };
   private Redraw_MACDFT = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      if (!this.kcGraphics) return;
      let YUnit = this.m_YInfo.YUnits[1];
      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;
      YUnit.SetTickBase(0.0001);

      let TECPData = this.m_TECPData.MACDFT;
      let MACDValues = TECPData.GetValues(this.m_XInfo.lXUnit);
      if (MACDValues && MACDValues.Min && MACDValues.Max) YUnit.ChangeYValue(MACDValues.Min, MACDValues.Max);

      let LinePaths = TECPData.GetPath(MACDValues, YUnit);

      let GSetting = this.m_TECPData.Setting.Global;
      let MACDSetting = this.m_TECPData.Setting.MACDFT;

      let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
      let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
      let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      // TopInfo
      let TopInfoData = TECPData.GetTopInfo();

      /* --------------------------------------------------------- */
      // BackGround
      var g_BG = this.kcGraphics;
      this.DrawBackground_Default(g_BG, YUnit, _TimeLines, _DailyLines);

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;
      let { RestoreClip } = kcGraphicsHelper.SetClip(g_TECP, {
         X: XStartCoord,
         Y: YUnit.nEffectStartY,
         Width: Math.max(0, XEndCoord - XStartCoord),
         Height: YUnit.nEffectHigh - 1,
      });

      // let Dif_MACD_UpColor = ToHex(MACDSetting.Dif_MACD_UpColor);
      // let Dif_MACD_DownColor = ToHex(MACDSetting.Dif_MACD_DownColor);
      let Dif_MACD_UpColor = this.m_TECPData.Setting.KLine.UpKColor; // 與KLine顏色同步
      let Dif_MACD_DownColor = this.m_TECPData.Setting.KLine.DownKColor; // 與KLine顏色同步

      if (MACDValues) {
         MACDValues.XUnitValues.forEach((md) => {
            let Data = md.Value;
            //let Dif_MACD = Data.DIF_MACD;
            let Dif_MACD = Data.L2;
            if (!Data || !Dif_MACD) return;
            let Y0 = YUnit.ValueToCoord(0);
            let YMACD = YUnit.ValueToCoord(Dif_MACD);
            let HDiffOC = Math.abs(YMACD - Y0);
            HDiffOC = Math.max(HDiffOC, 1);

            if (Dif_MACD >= 0) {
               kcGraphicsHelper.FillRect(g_TECP, Dif_MACD_UpColor, md.XUnit.fXLeftPos, YMACD, md.XUnit.fXWidth, HDiffOC);
            } else {
               kcGraphicsHelper.FillRect(g_TECP, Dif_MACD_DownColor, md.XUnit.fXLeftPos, Y0, md.XUnit.fXWidth, HDiffOC);
            }
         });

         if (LinePaths && LinePaths.length > 0) {
            for (let md of LinePaths) {
               let fLineWidth = md.PathWidth == 1 ? 1.5 : md.PathWidth;
               kcGraphicsHelper.DrawLine(g_TECP, fLineWidth, md.PathColor, md.Points);
            }
         }
      }

      RestoreClip();

      // TopInfo
      if (TopInfoData) this.Draw_TopInfo(g_BG, YUnit, GetText("TECP_FinTech"), [TopInfoData]);
   };

   private Redraw_Time = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      if (!this.kcGraphics) return;
      let YUnit = this.m_TECPData.Setting.Global.ShowDeputyTECP ? this.m_YInfo.YUnits[2] : this.m_YInfo.YUnits[1];

      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;

      let YMiddle = (YUnit.StartY + YUnit.EndY) / 2;

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;

      let cLineColor = kcColor("Border");
      let szFontColor = kcColor("SubTitle");

      this.DrawBackground_Border(g_TECP, YUnit);

      _TimeLines.forEach((_mdTime) => {
         kcGraphicsHelper.DrawLine(g_TECP, 2, cLineColor, [
            { X: _mdTime.XMidPos, Y: YUnit.StartY },
            { X: _mdTime.XMidPos, Y: YUnit.EndY },
         ]);

         let FontStyle: TextStyle = { Size: 12, Fill: szFontColor, HAlient: "center", VAlient: "middle" };
         kcGraphicsHelper.DrawText(g_TECP, _mdTime.Time.format(_mdTime.TimeFormat), _mdTime.XMidPos + 2, YMiddle, FontStyle);
      });
   };

   private Redraw_CheckLine = () => {
      if (!this.m_bCheckLine) return;
      if (!this.kcGraphics) return;
      if (!this.m_TECPData || !this.m_pCheckLinePos || !this.m_YInfo.YUnits || this.m_YInfo.YUnits.length == 0) return;

      // 共用參數
      let PosX = this.m_pCheckLinePos.X;
      let PosY = this.m_pCheckLinePos.Y;
      PosX = Math.min(this.m_XInfo.TECPEndLocation, Math.max(PosX, 0));
      PosY = Math.min(this.m_YInfo.PictureHeight - 1, Math.max(PosY, 0));

      // X定位
      let nDataIndex = this.m_XInfo.DrawPosToDataIndex(PosX);
      let FirstXUnit = ArrayExtensions.FindLast(this.m_XInfo.lXUnit, (q) => q.eXUnitDisplay == eXUnitDisplay.OverHalf || q.eXUnitDisplay == eXUnitDisplay.All);
      let LastXUnit = this.m_XInfo.lXUnit.find((q) => q.eXUnitDisplay == eXUnitDisplay.OverHalf || q.eXUnitDisplay == eXUnitDisplay.All);
      nDataIndex = Math.min(nDataIndex, LastXUnit?.nDataIndex ?? this.m_TECPData.mlOHLC.length - 1); // DataIndex最大值在最後一筆OHLC
      nDataIndex = Math.max(nDataIndex, FirstXUnit?.nDataIndex ?? 0);
      if (nDataIndex < 0) return;
      PosX = this.m_XInfo.DataIndexToDrawCenterPos(nDataIndex);

      // if (PosX < 0) {
      //   nDataIndex++;
      //   PosX = this.m_XInfo.DataIndexToDrawCenterPos(nDataIndex);
      // }
      // if (PosX >= this.m_XInfo.TECPWidth) {
      //   nDataIndex--;
      //   PosX = this.m_XInfo.DataIndexToDrawCenterPos(nDataIndex);
      // }

      // Y定位
      let FocusYUnit = this.m_YInfo.GetFocusYUnit(PosY);
      if (!FocusYUnit) return;
      let FixYValue = FocusYUnit.CoordToFixedValue(PosY);
      if (!FocusYUnit.FixedHeight) PosY = FixYValue.fFixedCoord; // 檔FixedHeight解DateTimeRow時Y會在0的問題

      // CkeckLine 價位 文字
      let bDrawPrice = PosY > FocusYUnit.nEffectStartY && PosY < FocusYUnit.nEffectEndY;
      let FloatNum = FocusYUnit.YTickBaseFloatNum;
      let rectPrice: { x: number; y: number; width: number; height: number } = {
         x: this.m_XInfo.TECPWidth + 1,
         y: PosY - 10,
         width: this.m_XInfo.PictureWidth - this.m_XInfo.TECPWidth - 1,
         height: 20,
      };

      // CkeckLine 時間 文字
      let LastYUnit = this.m_YInfo.YUnits[this.m_YInfo.YUnits.length - 1]; // 最後一個YUnit, Time的那個
      let bDrawTime = PosX >= this.m_XInfo.TECPStartLocation && PosX <= this.m_XInfo.TECPEndLocation; // 超出TECP範圍時(價位文字區域)不畫
      let TimeValue = this.m_TECPData.mlOHLC[nDataIndex]?.TimeLocal?.format("YYYY/MM/DD HH:mm:ss") ?? "";
      let nTimeFontSize = 14;
      let TimeRangeWidth = TimeValue !== "" ? 130 : 0;
      let rectTime: { x: number; y: number; width: number; height: number } = {
         x: PosX - TimeRangeWidth / 2,
         y: LastYUnit.StartY,
         width: TimeRangeWidth,
         height: LastYUnit.High - 1,
      };

      // LeftInfo參數
      let aLeftInfo: TopInfo[] = [];
      let TimeLeft = this.m_TECPData.LeftInfo_Time(nDataIndex);
      let KLineLeft = this.m_TECPData.LeftInfo_KLine(nDataIndex, this.m_SubTECP === SubTECP.MA, this.m_SubTECP === SubTECP.BBand, this.m_SubTECP === SubTECP.FinTechCloud);
      //let VolLeft = this.m_TECPData.LeftInfo_Vol(nDataIndex);
      aLeftInfo.push(...TimeLeft, ...KLineLeft);

      if (this.m_DeputyTECP === DeputyTECP.Vol) aLeftInfo.push(...this.m_TECPData.LeftInfo_Vol(nDataIndex));
      if (this.m_DeputyTECP === DeputyTECP.KD) this.m_TECPData.KD.AddLeftInfo(nDataIndex, aLeftInfo);
      if (this.m_DeputyTECP === DeputyTECP.RSI) this.m_TECPData.RSI.AddLeftInfo(nDataIndex, aLeftInfo);
      if (this.m_DeputyTECP === DeputyTECP.MACD) this.m_TECPData.MACD.AddLeftInfo(nDataIndex, aLeftInfo);
      if (this.m_DeputyTECP === DeputyTECP.MACDFT) this.m_TECPData.MACDFT.AddLeftInfo(nDataIndex, aLeftInfo);

      const HeightCloudWatch = 120;
      const LeftInfoPosOffset = 10;
      const LeftInfoVPadding = 5;
      const HeightPerLine = 20;
      const LeftInfoWidth = 140;
      let rectLeft: { x: number; y: number; width: number; height: number } = {
         x: PosX + LeftInfoPosOffset,
         y: PosY + LeftInfoPosOffset,
         width: LeftInfoWidth,
         height: aLeftInfo.length * HeightPerLine + LeftInfoVPadding * 2 + HeightCloudWatch + LeftInfoVPadding * 2,
      };
      if (rectLeft.x + rectLeft.width >= this.m_XInfo.TECPEndLocation) rectLeft.x = Math.max(0, PosX - rectLeft.width - LeftInfoPosOffset);
      if (rectLeft.y + rectLeft.height >= this.m_YInfo.PictureHeight) rectLeft.y = rectLeft.y - rectLeft.height / 2 - LeftInfoPosOffset;
      //rectLeft.y = Math.max(0, PosY - rectLeft.height - LeftInfoPosOffset);
      let LeftStrPosX = rectLeft.x + 10;
      let LeftStrPosY = rectLeft.y + LeftInfoVPadding;

      // 經斗雲座標
      let CloudIdx = this.m_TECPData.FinTechCloud.GetLeftFinTech(nDataIndex);

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;

      /* ---------------------------------------------------------------- */
      // 水平查價線 */
      if (bDrawPrice) {
         // 橫線
         let Y = Math.round(PosY);
         kcGraphicsHelper.DrawLine(g_TECP, 1.5, kcColor("Yellow"), [
            { X: 0, Y },
            { X: this.m_XInfo.PictureWidth, Y },
         ]);

         // 價位文字 黑底Rect
         kcGraphicsHelper.FillRect(g_TECP, kcColor("Background"), rectPrice.x, rectPrice.y, rectPrice.width, rectPrice.height);

         // 價位文字
         let FontStyle: TextStyle = { Fill: kcColor("Yellow"), Size: nTimeFontSize, HAlient: "center", VAlient: "middle" };
         kcGraphicsHelper.DrawText(g_TECP, FixYValue.dFixedValue.toFixed(FloatNum), rectPrice.x + rectPrice.width / 2, rectPrice.y + rectPrice.height / 2, FontStyle);
      }

      /* ---------------------------------------------------------------- */
      // 垂直查價線
      if (bDrawTime) {
         // 直線
         let X = Math.round(PosX);
         kcGraphicsHelper.DrawLine(g_TECP, 1.5, kcColor("Yellow"), [
            { X, Y: 0 },
            { X, Y: this.m_YInfo.PictureHeight },
         ]);

         // 時間文字 黑底Rect
         kcGraphicsHelper.FillRect(g_TECP, kcColor("Background"), rectTime.x, rectTime.y, rectTime.width, rectTime.height);

         // 時間文字
         let FontStyle: TextStyle = { Fill: kcColor("Yellow"), Size: nTimeFontSize, HAlient: "center", VAlient: "middle" };
         kcGraphicsHelper.DrawText(g_TECP, TimeValue, rectTime.x + rectTime.width / 2, rectTime.y + rectTime.height / 2, FontStyle);
      }

      /* ---------------------------------------------------------------- */
      // 查價線內容
      if (bDrawTime) {
         let szDefaultColor = kcColor("SubTitle");
         let MaxWidth = rectLeft.width;
         let Lines: (() => void)[] = []; // 每一個文字的draw

         // 每一條Text-Value
         for (let _Idx = 0; _Idx < aLeftInfo.length; _Idx++) {
            let _md = aLeftInfo[_Idx];
            let ConterPosY = LeftStrPosY + (_Idx + 0.5) * HeightPerLine + HeightCloudWatch + LeftInfoVPadding * 2;

            // Text
            let TextColor = _md.TextColor !== undefined ? _md.TextColor : szDefaultColor;
            let FontStyle_Text: TextStyle = { Size: 12, Fill: TextColor, VAlient: "middle" };
            Lines.push(() => {
               kcGraphicsHelper.DrawText(g_TECP, _md.Text, LeftStrPosX, ConterPosY, FontStyle_Text);
            });

            // Value
            let ValueColor = _md.Color !== undefined ? _md.Color : szDefaultColor;
            let FontStyle_Value: TextStyle = { Size: 12, Fill: ValueColor, VAlient: "middle" };
            Lines.push(() => {
               kcGraphicsHelper.DrawText(g_TECP, _md.Value, LeftStrPosX + 60, ConterPosY, FontStyle_Value);
            });

            // 計算最大寬度
            let { width } = kcGraphicsHelper.MeasureText(g_TECP, _md.Value);
            MaxWidth = Math.max(MaxWidth, width + 80);
         }

         // 內容區域 半透明黑底Rect
         kcGraphicsHelper.FillRoundedRect(g_TECP, "#000000C0", rectLeft.x, rectLeft.y, /*rectLeft.width*/ MaxWidth, rectLeft.height, 20, { bl: true, br: true, tl: true, tr: true }, kcColor("Border"), 1);

         // Image
         const ImagX = rectLeft.x + (MaxWidth - HeightCloudWatch) / 2;
         const ImagY = LeftStrPosY + LeftInfoVPadding;
         const Image_Cloud_watch = GetImage_Cloud_watch(CloudIdx);
         kcGraphicsHelper.DrawImage(g_TECP, Image_Cloud_Base, ImagX, ImagY, HeightCloudWatch, HeightCloudWatch, 0.75);
         if (Image_Cloud_watch) kcGraphicsHelper.DrawImage(g_TECP, Image_Cloud_watch, ImagX, ImagY, HeightCloudWatch, HeightCloudWatch, 0.75);

         for (let fLine of Lines) fLine();
      }
   };

   private DrawBackground_Default = (g_BG: kcGraphics, _YUnit: YUnit, _TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      this.DrawBackground_Border(g_BG, _YUnit);
      this.DrawBackground_HGrid(g_BG, _YUnit);
      this.DrawBackground_VGrid(g_BG, _YUnit, _TimeLines, _DailyLines);
   };

   private DrawBackground_Border = (g_BG: kcGraphics, _YUnit: YUnit) => {
      let YUnit = _YUnit;

      let cBorderColor = kcColor("Border");
      // height = 1 => 線粗2px
      // 不開反鋸齒 => Y + 0.5 => 因線粗為2, Y再-1
      kcGraphicsHelper.DrawRect(g_BG, 1, cBorderColor, 0, YUnit.EndY - 0.5, this.m_XInfo.PictureWidth, 1);
   };

   private DrawBackground_HGrid = (
      g_BG: kcGraphics,
      _YUnit: YUnit,
      _nlGridLines?: {
         Value: number;
         LineColor: string;
         LineWidth: number;
      }[]
   ) => {
      let YUnit = _YUnit;
      let FloatNum = YUnit.YTickBaseFloatNum;
      let GSetting = this.m_TECPData.Setting.Global;
      let cGridColor = kcColor("Border");

      let nlGridLines = _nlGridLines
         ? _nlGridLines
         : kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit).map((_nValue) => {
              return { Value: _nValue, LineColor: cGridColor, LineWidth: 1.5 };
           });
      let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
      let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
      let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      let szFontColor = kcColor("SubTitle");

      // 水平格線
      for (let Line of nlGridLines) {
         let YCoord = YUnit.ValueToCoord(Line.Value);
         const Y = Math.round(YCoord);
         if (GSetting.EnableHGridLine) {
            kcGraphicsHelper.DrawDashLine(g_BG, Line.LineWidth, Line.LineColor, [
               { X: XStartCoord, Y },
               { X: XEndCoord, Y },
            ]);
         }
         let szText = Line.Value.toFixed(FloatNum);

         let FontStyle: TextStyle = { Fill: szFontColor, HAlient: "center", VAlient: "middle" };
         let Size = kcGraphicsHelper.MeasureText(g_BG, szText);

         // 防止文字畫超過下範圍
         if (Size.height / 2 + Y < YUnit.nEffectEndY) {
            kcGraphicsHelper.DrawText(g_BG, szText, XFontMid, Y + 1, FontStyle);
         }
      }
   };

   private DrawBackground_VGrid = (g_BG: kcGraphics, _YUnit: YUnit, _TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      let YUnit = _YUnit;
      let GSetting = this.m_TECPData.Setting.Global;

      // 垂直格線
      if (GSetting.EnableVGridLine) {
         let Points: PathPoint[] = [];
         for (let _mdTime of _TimeLines) {
            let X = Math.round(_mdTime.XMidPos);
            Points.push({ X, Y: YUnit.StartY, StartPoint: true }, { X, Y: YUnit.EndY });
         }
         kcGraphicsHelper.DrawDashLine(g_BG, 1.5, kcColor("Border"), Points);
      }

      // 換日線
      if (GSetting.EnableDailyVGridLine) {
         let Points: PathPoint[] = [];
         for (let _mdTime of _DailyLines) {
            let X = Math.round(_mdTime.XMidPos);
            Points.push({ X, Y: YUnit.StartY, StartPoint: true }, { X, Y: YUnit.EndY });
         }
         kcGraphicsHelper.DrawDashLine(g_BG, 1, kcColor("Yellow_3"), Points);
      }
   };

   private Redraw_SpecHLine = (_TimeLines: BasicTimeModel[], _DailyLines: BasicTimeModel[]) => {
      if (!this.kcGraphics) return;
      let YUnit = this.m_YInfo.YUnits[0];
      if (!YUnit) return;
      if (this.m_XInfo.lXUnit.length == 0) return;

      let FloatNum = 2;
      if (this.m_TECPData?.kcUnit?.Commodity) {
         let Com = this.m_TECPData.kcUnit.Commodity;
         FloatNum = Com.FloatNum;
      }

      let XStartCoord = 0; // 水平線左邊
      // let XEndCoord = this.m_XInfo.TECPWidth - this.m_XInfo.TECPStartLocation - XInfo.FrameLineWidth; // 水平線右邊
      let XEndCoord = this.m_XInfo.TECPWidth + this.m_XInfo.TECPStartLocation + XInfo.FrameLineWidth; // 水平線右邊
      let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

      let SpecHLine: { sz: string; Value: number; szColor: string }[] = [];
      // if (
      //   this.m_TECPData.Setting.Global.EnableNowPriceHLine &&
      //   this.m_TECPData.mlOHLC.length != 0
      // ) {
      //   let mdHLine: { sz: string; Value: number; szColor: string } = {
      //     sz: "",
      //     Value:
      //       this.m_TECPData.mlOHLC[this.m_TECPData.mlOHLC.length - 1].ClosePrice,
      //     szColor: kcColor("Yellow_3"),
      //   };
      //   SpecHLine.push(mdHLine);
      // }
      if (this.m_TECPData.Setting.Global.EnableNowPriceHLine && this.m_TECPData.mlOHLC.length != 0) {
         if (this.m_TECPData.kcUnit?.LastInfo?.ClosePrice) {
            let mdHLine_Ask: { sz: string; Value: number; szColor: string } = {
               sz: "",
               Value: this.m_TECPData.kcUnit.LastInfo.ClosePrice,
               szColor: kcColor("Up"),
            };
            SpecHLine.push(mdHLine_Ask);
         }
         // if (this.m_TECPData.kcUnit?.LastInfo?.BidPrice) {
         //   let mdHLine_Bid: { sz: string; Value: number; szColor: string } = {
         //     sz: "",
         //     Value: this.m_TECPData.kcUnit.LastInfo.BidPrice,
         //     szColor: kcColor("Down"),
         //   };
         //   SpecHLine.push(mdHLine_Bid);
         // }
      }

      /* --------------------------------------------------------- */
      // TECP Image
      var g_TECP = this.kcGraphics;

      for (let Line of SpecHLine) {
         let YCoord = YUnit.ValueToCoord(Line.Value);
         if (Line.Value < YUnit.StartValue || Line.Value > YUnit.EndValue) return;
         let cLineColor = Line.szColor;

         const Y = Math.round(YCoord);
         kcGraphicsHelper.DrawDashLine(g_TECP, 1.5, cLineColor, [
            { X: XStartCoord, Y },
            { X: XEndCoord, Y },
         ]);

         kcGraphicsHelper.FillRect(g_TECP, cLineColor, XEndCoord, Y - 8, this.m_XInfo.PictureWidth - XEndCoord, 15);

         let FoneStyle_Max: TextStyle = { Fill: "#000000", HAlient: "center", VAlient: "middle" };
         kcGraphicsHelper.DrawText(g_TECP, Line.Value.toFixed(FloatNum), XFontMid, Y + 1, FoneStyle_Max);
      }
   };

   /*-------------------------------------------------*/

   Redraw = () => {
      this.RedrawTECP();
   };

   private Render_TECPs = () => {
      return (
         <canvas
            width={this.m_Width * Device.PixelRatio}
            height={this.m_Height * Device.PixelRatio}
            style={{ width: this.m_Width, height: this.m_Height, backgroundColor: kcColor("Background") }}
            ref={(r) => {
               let context2D = r?.getContext("2d") ?? undefined;
               if (context2D) {
                  this.kcGraphics = context2D;
                  this.Redraw();
               }
            }}
         />
      );
   };

   render() {
      // XInfo DataDis存檔
      if (this.m_Init) this.m_TECPData?.UpdateSetting_XDataDis(this.m_XInfo.DataDis);

      let IsHeightToLow = IsBottomTabHieghtToLow();

      return (
         <SafeAreaView
            style={{
               flex: 1,
               flexDirection: "column",
               backgroundColor: kcColor("Background"),
               paddingTop: 0,
               paddingBottom: 0,
            }}
         >
            <KC_HTypeModal ref={this.KC_HTypeModal} OnSelectHType={this.OnModalSelectHType} />
            <KC_TECPScreenTopInfo delCanUpdate={() => true} m_kcUnit={this.m_TECPData.kcUnit} OnItemClick={this.OnTopInfoItmeClick} Checking={this.m_bCheckLine} ShowOrder={!IsHeightToLow} ShowCommodity={IsHeightToLow} cHTypeValue={this.m_cHType.sz} />
            <View
               style={{
                  flex: 1,
                  flexDirection: "column",
                  backgroundColor: "#00000000",
                  alignItems: "stretch",
                  justifyContent: "space-around",
               }}
               onLayout={this.onLayout}
            >
               {this.state.loading && <ActivityIndicator size="large" style={{ alignSelf: "center" }} />}
               {!this.state.loading && (
                  <PanGestureHandler onGestureEvent={this.OnPanGestureEvent} onHandlerStateChange={this.OnPanGestureStateChange}>
                     <PinchGestureHandler onGestureEvent={this.OnPinchGestureEvent} onHandlerStateChange={this.OnPinchGestureStateChange}>
                        <TapGestureHandler
                           //waitFor={this.doubleTapRef}
                           onHandlerStateChange={this.OnTapHandlerStateChange}
                        >
                           {/* <TapGestureHandler
                    ref={this.doubleTapRef}
                    onHandlerStateChange={this.OnDoubleTapHandlerStateChange}
                    numberOfTaps={2}
                  > */}
                           <DefaultView>
                              <this.Render_TECPs />
                           </DefaultView>

                           {/* </TapGestureHandler> */}
                        </TapGestureHandler>
                     </PinchGestureHandler>
                  </PanGestureHandler>
               )}
            </View>
         </SafeAreaView>
      );
   }
}

const styles = StyleSheet.create({
   button: {
      backgroundColor: "#00000000",
      borderColor: "red",
      borderWidth: 0,
      borderRadius: 15,
   },
});
