import { IsLocale } from "../Locales";

export enum kcExchangeCode {
   UnDef = 0,
   CBT = 1,
   CME = 2,
   CMX = 3,
   NYM = 4,
   SMX = 5,
   TAIFEX = 6,
   TWSE = 7,
   ENX = 8, // [沒資料]
   ASX = 9, // [沒資料]
   CFE = 10,
   Eurex = 11,
   FX = 12, // [沒資料]
   HKEx = 13,
   ICEEU = 14,
   ICEUS = 15,
   INDEX = 16, // [沒資料]
   OSE = 17,
   TCE = 18, // [沒資料]
   SZSE = 19, // 深圳證券交易所 UTC +8 [沒資料]
   KRX = 20, // 韓國交易所 UTC +9 [沒資料]
   BalticEx = 21, // 波羅的海交易所(倫敦) UTC+0 [沒資料]

   // Polygon.io 美股
   XNYS = 22, // NYSE      紐約證券交易所
   XNAS = 23, // NASDAQ    那斯達克證券交易所
   XASE = 24, // AMEX      美國證券交易所
   BATS = 25, // BATS EXCHANGE BATS交易所
   ARCX = 26, // NYSE ARCA 紐約證券交易所ARCA
}
export const ExchangeToString = (_ExcCode: kcExchangeCode) => {
   let szName = "";

   if (IsLocale("zh-TW")) szName = ExchangeName(_ExcCode);
   else if (IsLocale("en")) szName = ExchangeName_enUS(_ExcCode);
   return `${kcExchangeCode[_ExcCode]} - ${szName}`;
};

const ExchangeName = (_ExcCode: kcExchangeCode): string => {
   switch (_ExcCode) {
      case kcExchangeCode.CBT:
         return "芝加哥期貨交易所";
      case kcExchangeCode.CME:
         return "芝加哥商業交易所";
      case kcExchangeCode.CMX:
         return "紐約金屬交易所";
      case kcExchangeCode.NYM:
         return "紐約商品期貨交易所";
      case kcExchangeCode.SMX:
         return "新加坡交易所";
      case kcExchangeCode.TAIFEX:
         return "台灣期貨交易所";
      case kcExchangeCode.TWSE:
         return "台灣證券交易所";
      case kcExchangeCode.ENX:
         return "法國期貨交易所";
      case kcExchangeCode.ASX:
         return "澳洲雪梨交易所";
      case kcExchangeCode.CFE:
         return "美國CBOE期貨交易所";
      case kcExchangeCode.Eurex:
         return "歐洲期貨交易所";
      case kcExchangeCode.HKEx:
         return "香港期貨交易所";
      case kcExchangeCode.ICEEU:
         return "英國洲際歐洲期貨交易所";
      case kcExchangeCode.ICEUS:
         return "美國洲際期貨交易所";
      case kcExchangeCode.OSE:
         return "大阪交易所";
      case kcExchangeCode.TCE:
         return "東京工業交易所";
      case kcExchangeCode.SZSE:
         return "深圳證券交易所";
      case kcExchangeCode.KRX:
         return "韓國交易所";
      case kcExchangeCode.BalticEx:
         return "波羅的海交易所";
      case kcExchangeCode.FX:
         return "國際外匯";
      case kcExchangeCode.INDEX:
         return "國際指數";
      case kcExchangeCode.XNYS:
         return "紐約證券交易所";
      case kcExchangeCode.XNAS:
         return "那斯達克證券交易所";
      case kcExchangeCode.XASE:
         return "美國證券交易所";
      case kcExchangeCode.BATS:
         return "美國BATS交易所";
      case kcExchangeCode.ARCX:
         return "紐約證券交易所ARCA";
      case kcExchangeCode.UnDef:
      default:
         return "未定義";
   }
};
const ExchangeName_enUS = (_ExcCode: kcExchangeCode): string => {
   switch (_ExcCode) {
      case kcExchangeCode.CBT:
         return "Chicago Board of Trade";
      case kcExchangeCode.CME:
         return "Chicago Mercantile Exchange";
      case kcExchangeCode.CMX:
         return "New York commodity Exchange";
      case kcExchangeCode.NYM:
         return "New York Mercantile Exchange";
      case kcExchangeCode.SMX:
         return "Singapore Exchange Limited";
      case kcExchangeCode.TAIFEX:
         return "Taiwan Futures Exchange";
      case kcExchangeCode.TWSE:
         return "Taiwan Stock Exchange";
      case kcExchangeCode.ENX:
         return "French stock exchange";
      case kcExchangeCode.ASX:
         return "Australian Securities Exchange";
      case kcExchangeCode.CFE:
         return "Chicago Board Options Exchange";
      case kcExchangeCode.Eurex:
         return "Eurex Exchange";
      case kcExchangeCode.HKEx:
         return "Hong Kong Futures Exchange";
      case kcExchangeCode.ICEEU:
         return "ICE Futures Europe";
      case kcExchangeCode.ICEUS:
         return "ICE Futures US";
      case kcExchangeCode.OSE:
         return "Osaka Exchange";
      case kcExchangeCode.TCE:
         return "Tokyo Commodity Exchange";
      case kcExchangeCode.SZSE:
         return "Shenzhen Stock Exchange";
      case kcExchangeCode.KRX:
         return "Korea Exchange";
      case kcExchangeCode.BalticEx:
         return "Baltic Exchange";
      case kcExchangeCode.FX:
         return "International foreign exchange";
      case kcExchangeCode.INDEX:
         return "International index";
      case kcExchangeCode.XNYS:
         return "New York Stock Exchange";
      case kcExchangeCode.XNAS:
         return "National Association of Securities Dealers Automated Quotations";
      case kcExchangeCode.XASE:
         return "American Stock Exchange";
      case kcExchangeCode.BATS:
         return "BATS Global Markets";
      case kcExchangeCode.ARCX:
         return "New York Stock Exchange ARCA";
      case kcExchangeCode.UnDef:
      default:
         return "undefined";
   }
};

export const ExcNameEx = (_ExcCode: kcExchangeCode) => {
   return `${ExchangeName_enUS(_ExcCode)} - ${ExchangeName(_ExcCode)}`;
};
export enum kcMarketCategory {
   UnDef = 0,
   Listed = 1,
   OTC = 2,
   Futures = 3,
   ROTC = 4,
   Option = 5,
}
export enum kcCommodityCategory {
   Unknow = 0x0,
   Index = 0x10,
   Stock = 0x20,
   StockWarrant = 0x21,
   CommodityFuture = 0x30,
   IndexFuture = 0x31,
   StockFuture = 0x32,
   InterestRateFuture = 0x33,
   BondFuture = 0x34,
   CurrFuture = 0x35,
   IndexOption_Call = 0x40,
   IndexOption_Put = 0x48,
   StockOption_Call = 0x41,
   StockOption_Put = 0x49,
   FutureOption_Call = 0x42,
   FutureOption_Put = 0x4a,
   CurrOption_Call = 0x44,
   CurrOption_Put = 0x4c,
}
export const CategoryText = (_Category: kcCommodityCategory): string => {
   if (IsLocale("zh-TW")) return CategoryText_zhTW(_Category);
   else if (IsLocale("en")) return CategoryText_enUS(_Category);
   return CategoryText_zhTW(_Category); // Default
};
const CategoryText_zhTW = (_Category: kcCommodityCategory): string => {
   switch (_Category) {
      case kcCommodityCategory.Index:
         return "指數";
      case kcCommodityCategory.Stock:
         return "股票";
      case kcCommodityCategory.StockWarrant:
         return "權證";
      case kcCommodityCategory.CommodityFuture:
         return "商品期貨";
      case kcCommodityCategory.IndexFuture:
         return "指數期貨";
      case kcCommodityCategory.StockFuture:
         return "股票期貨";
      case kcCommodityCategory.BondFuture:
         return "債券期貨";
      case kcCommodityCategory.CurrFuture:
         return "貨幣期貨";

      case kcCommodityCategory.IndexOption_Call:
         return "指數選擇權買權";
      case kcCommodityCategory.IndexOption_Put:
         return "指數選擇權賣權";
      case kcCommodityCategory.StockOption_Call:
         return "股票選擇權買權";
      case kcCommodityCategory.StockOption_Put:
         return "股票選擇權賣權";
      case kcCommodityCategory.FutureOption_Call:
         return "期貨選擇權買權";
      case kcCommodityCategory.FutureOption_Put:
         return "期貨選擇權賣權";
      case kcCommodityCategory.CurrOption_Call:
         return "貨幣選擇權買權";
      case kcCommodityCategory.CurrOption_Put:
         return "貨幣選擇權賣權";
      case kcCommodityCategory.InterestRateFuture:
      case kcCommodityCategory.Unknow:
      default:
         return kcCommodityCategory[_Category];
   }
};
const CategoryText_enUS = (_Category: kcCommodityCategory): string => {
   switch (_Category) {
      case kcCommodityCategory.Index:
         return "Index";
      case kcCommodityCategory.Stock:
         return "Stock";
      case kcCommodityCategory.StockWarrant:
         return "Warrant";
      case kcCommodityCategory.CommodityFuture:
         return "Commodity futures";
      case kcCommodityCategory.IndexFuture:
         return "Index futures";
      case kcCommodityCategory.StockFuture:
         return "Stock futures";
      case kcCommodityCategory.BondFuture:
         return "Bond futures";
      case kcCommodityCategory.CurrFuture:
         return "currency futures";

      case kcCommodityCategory.IndexOption_Call:
         return "指數選擇權買權";
      case kcCommodityCategory.IndexOption_Put:
         return "指數選擇權賣權";
      case kcCommodityCategory.StockOption_Call:
         return "股票選擇權買權";
      case kcCommodityCategory.StockOption_Put:
         return "股票選擇權賣權";
      case kcCommodityCategory.FutureOption_Call:
         return "期貨選擇權買權";
      case kcCommodityCategory.FutureOption_Put:
         return "期貨選擇權賣權";
      case kcCommodityCategory.CurrOption_Call:
         return "貨幣選擇權買權";
      case kcCommodityCategory.CurrOption_Put:
         return "貨幣選擇權賣權";
      case kcCommodityCategory.InterestRateFuture:
      case kcCommodityCategory.Unknow:
      default:
         return kcCommodityCategory[_Category];
   }
};
export enum kcIndustryCategory {
   UnDef = 0,
   化學工業 = 1,
   文化創意業 = 2,
   水泥工業 = 3,
   半導體業 = 4,
   生技醫療業 = 5,
   光電業 = 6,
   汽車工業 = 7,
   其他業 = 8,
   其他電子業 = 9,
   油電燃氣業 = 10,
   金融保險業 = 11,
   建材營造業 = 12,
   玻璃陶瓷 = 13,
   食品工業 = 14,
   紡織纖維 = 15,
   航運業 = 16,
   通信網路業 = 17,
   造紙工業 = 18,
   貿易百貨業 = 19,
   塑膠工業 = 20,
   資訊服務業 = 21,
   農業科技業 = 22,
   電子商務 = 23,
   電子通路業 = 24,
   電子零組件業 = 25,
   電腦及週邊設備業 = 26,
   電器電纜 = 27,
   電機機械 = 28,
   橡膠工業 = 29,
   鋼鐵工業 = 30,
   觀光事業 = 31,
   存託憑證業 = 32,
}
export enum TradeTimeState {
   UnDef = 0,
   //Stop = 0,
   //Start = 1,
   //Clear = 2,
   Open = 4,
   Close = 5,
   //PreOpen = 5,
   //Suspend = 6,
   //Remove = 9
}
export enum DayKLineType {
   Day = 1,
   Week = 5,
   Month = 20,
   Season = 60,
   HalfYear = 120,
   Year = 240,
}
export enum CurrencyType { // 貨幣
   UnKnow = 0,
   CNH = 1,
   CNY = 2,
   EUR = 3,
   GBP = 4,
   JPY = 5,
   NTD = 6,
   SGD = 7,
   USD = 8,

   TWD = NTD,
}

const _PriceInDepthType = ["Unknow", "Bid", "CloseBid", "Between", "CloseAsk", "Ask"] as const;
export type PriceInDepthType = (typeof _PriceInDepthType)[number];
export var PriceInDepthTypeArray = _PriceInDepthType;
