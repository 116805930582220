import moment, { Moment } from "moment";
import { CompressionExtensions, MomentExtensions, StringConverter } from "../kcExternal";
import { PriceInDepthType } from "./kcModel_Enum";
import kcTickModel from "./kcTickModel";
import kcDepthModel from "./kcDepthModel";

export default class kcLastInfoModel {
   constructor(props: object) {
      Object.assign(this, { ...props });
      this.FixedTime();
   }

   /* ---------------------------Member--------------------------- */
   StockCode: string = "";
   Time: Moment = moment(0); // DateTime (8 bytes double OADate)
   PreClosePrice: number = 0;
   BasePrice: number = 0;
   LimitUpPrice: number = 0;
   LimitDownPrice: number = 0;
   OpenPrice: number = 0;
   HighPrice: number = 0;
   LowPrice: number = 0;
   ClosePrice: number = 0;
   Vol: number = 0;
   TotalVol: number = 0;
   Simulate: boolean = false;
   PriceInDepth: PriceInDepthType = "Unknow";
   TotalAskVol: number = 0;
   TotalBidVol: number = 0;
   DepthTime: Moment = moment(0);
   AskPrice1: number = 0;
   AskVolume1: number = 0;
   BidPrice1: number = 0;
   BidVolume1: number = 0;

   public get TimeLocal() {
      return MomentExtensions.ToLocalTime(this.Time);
   }

   ToString() {
      let szRet = "";
      szRet += this.Time.utc().format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
      szRet += this.ClosePrice + ", ";
      szRet += this.Vol + ", ";
      szRet += this.TotalVol + ", ";
      return szRet;
   }

   Update_ByLastInfo(_mdLastInfo: kcLastInfoModel) {
      Object.assign(this, { ..._mdLastInfo });
      this.FixedTime();
   }

   Update_ByTick(_mdTick: kcTickModel) {
      Object.assign(this, { ..._mdTick });
      this.FixedTime();

      let Price: number = _mdTick.ClosePrice; // 成交價
      if (!_mdTick.Simulate) {
         if (this.OpenPrice === 0) this.OpenPrice = Price;
         if (this.HighPrice < Price) this.HighPrice = Price;
         if (this.LowPrice > Price) this.LowPrice = Price;
      }
      this.ClosePrice = Price;
   }
   Update_ByDepth(_mdDepth: kcDepthModel) {
      Object.assign(this, { ..._mdDepth });
      this.FixedTime();
   }

   private FixedTime() {
      this.Time = MomentExtensions.Parse(this.Time);
      this.DepthTime = MomentExtensions.Parse(this.DepthTime);
   }

   /* ---------------------------static Function--------------------------- */
   static CreateList_FromJSon(ZipJSonBuffer: Uint8Array): kcLastInfoModel[] {
      let JSonBuffer = CompressionExtensions.Zlib_UnZip(ZipJSonBuffer);
      let szJSon: string = StringConverter.UTF8_GetString(JSonBuffer);
      let Obj = JSON.parse(szJSon);

      let mlLastInfo: kcLastInfoModel[] = [];
      if (Obj) {
         if (Array.isArray(Obj)) {
            mlLastInfo = Obj.map((q) => new kcLastInfoModel(q));
            for (let md of mlLastInfo) {
               if (md.ClosePrice === 0) md.ClosePrice = md.PreClosePrice;
            }
         } else {
            var md = new kcLastInfoModel(Obj);
            if (md.ClosePrice === 0) md.ClosePrice = md.PreClosePrice;
            mlLastInfo.push(md);
         }
      }
      return mlLastInfo;
   }
}
